import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import ChartBase from './ChartBase';
export const _frontmatter = {
  "title": "정보 공개 제도 잘 활용하고 있는가?",
  "slug": "data10",
  "date": "2022-03-21T15:00:00.000Z",
  "thumb": "../images/thumbs/thumb10.png",
  "featuredImg": "../images/featured/featured10.png",
  "summary": "한국인은 정보 공개에 대해 얼마나 알고 있을까, 그리고 잘 활용하고 있을까?",
  "tags": ["정책", "정보공개", "공익"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`국민의 알 권리가 충분하게 보장되는 사회는 주요 권력기관들이 충실하게 정보를 공개한다. 하지만 '알 권리가 보장되지 않는 곳에서는 시민과 국가가 정보의 공개를 두고 줄다리기를 펼친다. 어디까지 공개할 수 있는가가 그 사회의 '알 권리' 수준을 판가름하는 기준이 된다.`}<br parentName="p"></br>{`
`}{`우리나라는 1998년에 세계 13번째, 아시아 국가 중 처음으로 정보 공개법을 시행했다. 그리고 지난 20여 년 동안 막대한 예산을 쏟아 첨단 시스템을 구축했다. 하지만 정작 중요한 정보는 빼놓거나 별 이유 없이 공개하기를 꺼린다. 허울만 좋은 정보 공개'라는 평가가 나오는 이유다. 한국인은 정보 공개에 대해 얼마나 알고 있을까, 그리고 잘 활용하고 있을까?`}</p>
    <h3>{`아직 잘 알려지지 않은 정보 공개 제도`}</h3>
    <p>{`조사 결과 정보 공개 제도는 국민에게 낯설다. 정보 공개 제도에 대해 잘 안다고 응답한 사람은 10명 중 1명이 채 되지 않았다. 어느 정도 알고 있다는 사람이 36.4%이지만 전혀 모르거나 이름만 들어봤다는 사람이 절반을 넘었다. 20대와 30대 중에 정보 공개 제도를 전혀 모르는 사람이 20%를 넘었다. 정보 공개 제도에 관한 이해는 남녀별로도 차이가 컸다.`}<br parentName="p"></br>{`
`}{`더 나아가 직접 정보 공개를 청구한 경험이 있는 사람은 10명 중 1명 정도밖에 되지 않았다. 이 비율 또한 남녀 차이가 있었다.`}</p>

    <h2>{`앞으로 정보 공개 청구 제도를 활용할 의사가 있으십니까?`}</h2>
    <ChartBase type={'pie'} data={props.data} columns={props.columns} mdxType="ChartBase"></ChartBase>
    <p>{`정보 공개 제도에 대한 한국인의 이해는 매우 낮다. 이번 조사를 수행한 피플네트 웍스리서치 서명원 대표는 실제 청구 경험 비율 등을 기준으로 보면 어느 정도 알고 있다는 응답도 실제보다 더 높이 나왔을 가능성이 있다고 지적했다.`}<br parentName="p"></br>{`
`}{`그러나 정보 공개 청구 제도를 활용해보고 싶은 의사는 매우 높게 나왔다. 정보 공개 제도를 활용할 의사가 있느냐는 질문에 매우 그렇다'와 '그렇다'로 응답한 사람을 합치면 75%를 넘었다. 정보 공개 제도를 잘 알고 있거나 청구한 경험이 있는 사람일수록 활용하고자 하는 의사가 더 강했다.`}</p>
    <h3>{`정보 공개 제도를 활용하고 싶은 분야`}</h3>
    <p>{`정보 공개 제도를 활용하고 싶은 분야는 세금 · 재정 · 경제, 건강 · 복지, 법무·검찰·사법, 정치·외교·안보 순이었는데 연령대별로 차이가 컸다. 경제 활동을 시작한 20대와 30대는 세금·재정·경제에 관심이 가장 컸고 60대 이상은 '건강·복지 관련 정보를 선호했다. 40대는 자녀 교육에 대한 관심 때문에 '교육·문화를 택한 비율이 11.5%로 6% 안팎에 그친 다른 연령대보다 2배가량 높았다.  `}</p>
    <h3>{`정보 공개 제도 이용의 어려움`}</h3>
    <p>{`연령대별로 정보 공개 제도를 활용할 때의 어려움도 차이가 있었다. 50대와 60대, 이상의 장년층은 인터넷 활용이 가장 큰 장애물로 나타났다. 직장 생활을 하는 20~40대는 시간 부족이 가장 큰 어려움이라고 응답했다.`}</p>
    <h6>{`*<세계일보>와 공공의창이 기획하고 여론조사기관 피플네트웍스리서치가 조사를 수행했다. 2019년 3월 11일, 전국 성인남녀 1,003명을 대상으로 무선전화 ARS 방식으로 진행했다. 응답율은 3.8%, 표본오차는 95% 신뢰수준에 최대 허용 오차는 ±3.1%p다. 대상자 표집은 2019년 행정안전부가 발표한 주민등록 인구를 기준 삼아 성·연령·지역별로 비례 할당했다.`}</h6>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      